// @ts-ignore
import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button, Input, Typography, Spin } from "antd";
import Card from "./card.tsx";
import Graph from "./graph.tsx";
import TableSection from "./table-section.tsx";
import dayjs from "dayjs";
import Sidebar from "../../layout/sidebar/view.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import useOpenStats from "../../hooks/use-stats.tsx";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import { RootState } from "../../redux/store.ts";
import RightArrow from "../../assets/images/right_arrow.svg";
import Booking from "./booking.tsx";
import axiosClient from "../../utils/axios.tsx";
import Cookies from "js-cookie";

const Main = () => {
  const { Text } = Typography;
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [viewData, setViewData] = useState();
  const [isBooking, setIsBooking] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [isGraphLoading, setisGraphLoading] = useState(false);
  const [year, setYear] = useState(dayjs(new Date()));

  interface Values {
    from: any;
    to: any;
  }
  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  const { user } = useAuthContext();
  const userId = Cookies.get("affiliate-user-id");
  const stats = useSelector((state: RootState) => state.stats.stats);
  const { getStats } = useOpenStats();
  const { getPayouts } = useOpenPayouts();
  const [copied, setCopied] = useState(false);

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const CustomBtnIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(27).svg?updatedAt=1709811522884"
        alt=""
        className="w-[15px] h-[15px]"
      />
    );
  };

  const handleCopy = () => {
    const textToCopy = `${
      process.env.REACT_APP_REFERRAL_FORM_DOMAIN || "www.domain.com"
    }?ref=${user?.user_id ? user?.user_id : "no ref"}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    getStats(values, user?.user_id && user);
    getPayouts(user?.user_id && user); //we don't need to pass date here.
  }, [values, user]);

  useEffect(() => {
    setUserCompanies(stats?.userCompanies);
  }, [stats]);

  const TOTAL_CARDS = [
    {
      label: "Unpaid",
      counts: ` $${
        user?.is_verified && stats?.userCommission
          ? stats?.userCommission?.unpaid?.toFixed(2)
          : "0"
      }`,
    },
    {
      label: "Paid",
      counts: ` $${
        (user?.is_verified && stats?.userCommission?.paid?.toFixed(2)) || "0"
      }`,
    },
    {
      label: "Total earnings",
      counts: `$${
        (user?.is_verified && stats?.userCommission?.total?.toFixed(2)) || "0"
      }`,
    },
  ];
  const TOTAL_CARDS2 = [
    {
      label: "Visitors",
      counts: stats?.visitors || 0,
    },
    {
      label: "Leads",
      counts: stats?.leadsCompanies || 0,
    },
    {
      label: "Converted",
      counts: (user?.is_verified && stats?.convertedCompanies) || 0,
    },
    {
      label: "Conversion rate",
      counts: `${
        (user?.is_verified &&
          stats?.leadsCompanies &&
          `${(
            (stats?.convertedCompanies / stats?.leadsCompanies) *
            100
          ).toFixed(2)}%`) ||
        "0"
      }`,
    },
  ];

  useEffect(() => {
    if (userId){
    setisGraphLoading(true);
    axiosClient()
      .get(
        `/pipedrive-organisations/user-graph?${userId ? "user_id=" + userId : ""}${
          year ? "&Year=" + year["$y"] : ""
        }`
      )
      .then((res) => {
        setGraphData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setisGraphLoading(false));
    }
  }, [year]);

  const handleSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = stats?.userCompanies;
    } else {
      filteredData = stats?.userCompanies?.filter((x) => {
        return x?.Name?.toLowerCase().includes(value?.toLowerCase());
      });
    }
    setUserCompanies(filteredData);
  };

  return (
    <>
      {user?.name ? (
        <>
          <div className="container pt-28 ">
            {!user?.is_verified && (
              <>
                <Text className="text-[#101828] text-[30px] font-semibold ">
                  Welcome, <span className="capitalize">{user?.name}</span>
                </Text>
                <div className="w-full p-[16px] bg-[#FFFCF5] border mt-5 border-[#FEC84B] rounded-[12px] flex items-start">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(35).svg?updatedAt=1711530043666"
                    alt=""
                  />

                  <div className="grid pl-3">
                    <Text className="text-[#B54708] -mt-1 text-[14px] font-semibold">
                      Your account is pending approval from Glovendor.
                    </Text>
                    <Text className="text-[#B54708] text-[14px] font-normal">
                      You will receive an email once your account is activated.
                      If you would like to learn more about the Glovendor
                      Partner Program please book a support call using the link
                      below.
                    </Text>
                    <Text
                      onClick={() => setIsBooking(true)}
                      className="text-[#DC6803] cursor-pointer text-[14px] flex gap-2 items-center font-semibold mt-2"
                    >
                      <text className="underline">Book a meeting</text>
                      <div>
                        <img src={RightArrow} />
                      </div>
                    </Text>
                  </div>
                </div>
              </>
            )}

            {user?.is_verified && (
              <>
                <div className="grid">
                  <Text className="text-[#101828] text-[30px] font-semibold capitalize headingText">
                    Welcome back, {user?.name}
                  </Text>
                  <Text className="text-[#475467] text-[16px]">
                    Use your affiliate link to send leads towards Glovendor.
                  </Text>
                </div>
                <div className="flex items-center mt-4 copyBtn">
                  <Input
                    className="w-[380px] h-[44px] rounded-l-[8px] placeholder:font-bold placeholder:text-[#667085] font-semibold rounded-r-none border border-[#D0D5DD]"
                    placeholder=""
                    value={`${
                      process.env.REACT_APP_REFERRAL_FORM_DOMAIN ||
                      "www.domain.com"
                    }?ref=${user?.user_id ? user?.user_id : "no ref"}`}
                  />
                  <Button
                    type="primary"
                    icon={<CustomBtnIcon />}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderRight: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      borderRadius: "0px, 8px, 8px, 0px",
                    }}
                    onClick={handleCopy}
                    className="flex-none bg-transparent shadow-none h-[44px] text-[#344054] font-bold rounded-r-[8px] rounded-l-none bg-white"
                  >
                    {copied ? "Copied!" : "Copy"}
                  </Button>
                </div>
              </>
            )}

            <div className="grid mt-5">
              <Text className="text-[#101828] font-semibold text-[24px]">
                Your Earnings
              </Text>
              <Text className="text-[#475467] text-[16px] font-normal">
                {!user?.is_verified
                  ? "Use your affiliate link to send leads towards Glovendor."
                  : "Overview of your earnings."}
              </Text>
            </div>

            <Row gutter={30}>
              {TOTAL_CARDS?.map((item: any, index: number) => (
                <Col key={index} span={8} className="mt-8">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>

            <div className="dateBox mt-5 pt-2">
              <div style={{ display: "block" }}>
                <Text
                  style={{ display: "block" }}
                  className="text-[#101828]  font-semibold text-[24px]"
                >
                  Your Referrals
                </Text>
                <Text className="text-[#475467] text-[16px] font-normal">
                  {!user?.is_verified
                    ? "Use your affiliate link to send leads towards Glovendor."
                    : "Your current summary and activity."}
                </Text>
              </div>
              <div className="w-[5.4rem]">
                <DatePicker
                  className="dateYear"
                  picker="year"
                  minDate={dayjs(new Date(2022, 0, 1))}
                  maxDate={dayjs(new Date())}
                  value={year}
                  onChange={(e) => setYear(e)}
                  allowClear={false}
                  disabled={isGraphLoading}
                />
              </div>
            </div>

            <Row>
              <Col span={24} className="mt-8 overflow-hidden">
                <Col
                  style={{
                    boxShadow: "0px 1px 2px 0px #1018280F",
                    background: "#fff",
                    borderRadius: "12px",
                  }}
                >
                  {!isGraphLoading ? (
                    <Graph
                      graphData={graphData?.result}
                      isShow={user?.is_verified}
                    />
                  ) : (
                    <div className="flex items-center justify-center py-[9.1rem]">
                      <Spin />
                    </div>
                  )}
                </Col>
              </Col>
            </Row>

            <Row gutter={30}>
              {TOTAL_CARDS2?.map((item: any, index: number) => (
                <Col key={index} span={6} className="mt-8 ">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>
            <Row>
              <Col span={24} className="my-8">
                <Input
                  style={{
                    boxShadow: "0px 1px 2px 0px #1018280D",
                  }}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search"
                  prefix={<SearchIcon />}
                  className="w-[400px] h-[44px] placeholder:text-black rounded-[8px] inputSearch"
                />
              </Col>
              <Col span={24} className="mb-8 tableBoxed">
                <TableSection
                  tableData={user?.is_verified ? userCompanies : []}
                  setIsViewOpen={setIsViewOpen}
                  setViewData={setViewData}
                />
              </Col>
              <Sidebar
                isViewOpen={isViewOpen}
                setIsViewOpen={setIsViewOpen}
                isCompany
                data={viewData}
                setViewData={setViewData}
              />
            </Row>
          </div>
        </>
      ) : (
        <Spin fullscreen />
      )}
      <Booking isOpen={isBooking} onClose={() => setIsBooking(false)} />
    </>
  );
};

export default Main;
