import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth-context.tsx";

export const AuthGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, user } = useAuthContext();
  const ignore = useRef(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ignore.current = true;

    if (!isAuthenticated) {
      navigate("/");
    } else {
      setChecked(true);
    }

  }, [isAuthenticated, user]);

  if (!checked) {
    return null;
  }

  return children;
};
