import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const Graph: React.FC<{
  isShow: boolean;
  graphData: Array<{ month: string; region: string; value: number }> | undefined;
}> = ({ isShow, graphData }) => {
  const series = useMemo(() => {
    const regions = ["Visitors", "Leads", "Converted"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    if (!graphData || graphData.length === 0) {
      // Return default data if graphData is undefined or empty
      return regions.map(region => ({
        name: region,
        data: Array(12).fill(0)
      }));
    }
    
    return regions.map(region => ({
      name: region,
      data: months.map(month => {
        const dataPoint = graphData.find(d => d.month === month && d.region === region);
        return dataPoint ? dataPoint.value : 0;
      })
    }));
  }, [graphData]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: '30%' // Ensures columns fill the available width
      }
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yaxis: {
      title: {
        text: undefined
      },
      tickAmount: 5
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    colors: ['#004EEB', '#2970FF', '#84ADFF'],
    dataLabels: {
      enabled: false // Disable data labels
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={300} />
    </div>
  );
}

export default Graph;

